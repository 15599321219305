import { Loading } from '@snapi/luxury';
import { useAuth } from '@snapi/ui-utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function RegisterPage() {
  const { state } = useAuth();
  const router = useRouter();
  useEffect(() => {
    if (state.loaded) {
      if (state.authorized) {
        router.replace('/dashboard');
      } else {
        router.replace('/auth/register');
      }
    }
  }, [state.loaded, state.authorized, router]);

  return <Loading />;
}
