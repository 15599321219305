import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

type SpinnerIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const SpinnerIcon: React.FC<SpinnerIconProps> = (
  props: SpinnerIconProps
) => <FontAwesomeIcon icon={faCircleNotch} spin {...props} />;
